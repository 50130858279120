import React from 'react';
const Config = {
  url: window.location.origin,
  userName: '',
  passWord: '',
};

if (ReactIsInDevelomentMode()) {
  Config.url = '';
  Config.userName = 'admin';
  Config.passWord = 'Solutions!';
}

export default Config;

export function ReactIsInDevelomentMode() {
  return '_self' in React.createElement('div');
}
